.table_data {
  display: flex;
  justify-content: center;
}
.card_table {
  background: #f2f2f2;
  box-shadow: 3px 3px 13px #dededebf;
  border-radius: 20px;
  width: 100%;
  padding: 20px;
}

.sc-hKFxyN.sc-eCApnc.sc-iqAclL.kXbQov.jFwrzr.rdt_TableCol {
  color: #855bf5;
}
/* .sc-jrsJWt.flbcPw.rdt_TableRow,
.sc-jrsJWt.hztQPW.rdt_TableRow {
  background: #fff;
  border-radius: 10px;
  margin: 2px 0;
  padding: 10px 0;
  border: 1px solid lightgray;
  justify-content: center;
} */
div#cell-6-undefined,
div#cell-3-undefined,
div#cell-1-undefined {
  color: #252733;
  font-weight: 600;
}

div#cell-5-undefined,
div#cell-7-undefined,
div#cell-2-undefined {
  color: #5c5c5c;
  font-size: 11px;
  font-weight: 500;
}

div#cell-4-undefined {
  color: #1ea056;
  font-size: 14px;
  font-weight: 500;
}
/* .data-table-extensions {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
button.print,
button.download {
  display: none;
}
button {
  border: none;
  background: #855BF5;
  color: #fff;
  padding: 4px 15px;
  border-radius: 5px;
  margin: 0 8px;
}
input.filter-text {
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  padding: 4px 15px;
  font-size: 14px;
}
input.filter-text:focus {
  outline: none;
}

.menu-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  /* margin-bottom:-37px; */
}

.rdt_TableRow {
  background-color: white;
}
.cm-pic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.myCol {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cmCity {
  font-size: 18px;
  color: #aa455c;
  font-weight: 300;
  margin: 4px 0;
}

.colTitle {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
}
.colValue {
  font-weight: 200;
  color: #5B3EBA;
  font-size: 27px;
  margin: 0;
  margin-top: 7px;
}
.onBoardStatus {
  background: #e7e7e7d1;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 8px;
}
/* .active {
  background: #ceeaa3d1;
} */
.inActive {
  background: #ff8f8fd1;
}
/* .hold {
  background: rgba(255, 166, 0, 0.356);
} */
.fixStatusCard {
  min-width: 110px;
  max-width: 129px;
}
.dateStyle {
  font-size: 9px;
  font-weight: 100;
  color: #9879ff;
}

.data-table-extensions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.col_subtitle {
  color: #B2B2B2;
  font-size: 10px;
  font-weight: 300;
  margin: 0;
  margin-top: -2px;
}

.col_value2 {
  color: #2DA0BC;
  font-weight: 500;
  font-size: 27px;
  margin: 0;
  margin-top: 7px;
}
.col_value3 {
  color: #121212;
  font-size: 11px;
  font-weight: 400;
  margin-top: -4px;
}
.hold {
  background: #FFB33933;
  color: #FF8900;
}
.active {
  background: #238E4827;
  color: #238E48;
}
.sc-jrsJWt.flbcPw.rdt_TableRow, .sc-jrsJWt.hztQPW.rdt_TableRow {
  background: #fff;
  border-radius: 10px;
  margin: 12px 0;
  padding: 10px;
  padding-top: 25px;
  justify-content: center;
  display: flex;
  align-items: flex-start;
}
.sc-gtsrHT.hxohyr.rdt_TableHead {
  display: none;
}
a.dropdown-item {
  padding: 0px;
}
.cm-image{
  height: 48px;
  width: 48px;
  object-fit: cover;
  border-radius: 50px;
}