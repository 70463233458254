.pa_sidebar {
    border: 1px solid #E0E0E0;
    background: #8f6efc45;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 190px;
}
.side_row1 {
    display: flex;
    justify-content: center;
    margin: 25px 0px;
}
.side_box {
    width: 110px;
    padding: 20px;
    padding-right: 0;
}
h3.side_h3 {
    font-size: 14px;
    margin: 0;
}
p.side_p1 {
    font-size: 11px;
    margin: 4px 0;
    color: #fff;
}
img.side_profile {
    border-radius: 50%;
    margin-left: 5px;
    object-fit: cover;
    height: 73px;
    width: 73px;
}
.side_tabs {
    cursor: pointer;
    font-size: 11px;
    background: #B59FFD2B;
    padding: 8px;
    margin: 10px 0px;
    padding-left: 25px;
    /* font-weight: 600; */
    font-size: 12px;
}

/* .side_tabs1 {
    cursor: pointer;
    font-size: 11px;
    background: #fff;
    color: #8F6EFC;
    border-right: 5px solid #121212;
    padding: 8px;
    margin: 10px 0px;
    padding-left: 25px;
} */
.side_tabs1 {
    cursor: pointer;
    background: #fff;
    color: #673695;
    border-right: 5px solid #121212;
    padding: 8px;
    margin: 10px 0px;
    padding-left: 25px;
    font-weight: 600;
    font-size: 12px;

}