.tsm_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    background: #ffffff;
    max-height: 100vh;
    color: #000;
    /* contain: content; */
    /* overflow-y: auto; */
    margin-top: 17px;
}
.tsm_row1 {
    display: flex;
}
.tsm_row2 {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    margin-top: 40px;
}
.tsm_row3 {
    width: 100%;
    display: flex;
    cursor: pointer;
}
.tsm_row3:hover { 
    background-color: #ddcaff;
}
.image_profile2 {
    width: 40px;
}

.border_right1 {
    border-right: 1px solid #eeeeee;
    /* border-bottom: 4px solid black; */
    height: 40px;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: flex-start; */
    font-size: 12px;
    text-align: left;
}

.display_center {
    display: flex;
    justify-content: center;
    align-items: center;

}

.tv_dash_row22 {
    font-size: 13px;
    background: #7e57c2;
    width: 87%;
    color: #fff;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    font-weight: 700;
    position: fixed;
}
.myTeam_card11 {
    box-shadow: 0px 0px 20px #d9d9d967;
    border-radius: 20px;
    padding: 20px;
    margin: AUTO;
    background: #fff;
    width: 95%;
    margin-top: 75px;
}
.padding_left {
    padding-left: 20px;
    align-items: center;
}