/* .dashtl1_card_row {
  display: flex;
  justify-content: space-between;
  width: 50%;
} */

/* .dashtl1_row1 {
  padding: 0px 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dashtl_card {
  margin: 20px 0;
  box-shadow: 10px 15px 80px #dedede65;
  border-radius: 15px;
  padding: 20px;
  width: 46%;
  background: #fff;
}
.dashtl_card_row1 {
  display: flex;
  justify-content: space-between;
}
.dashtl1_card_col1 {
  display: flex;
}
img.prof_img {
  width: 100px;
  height: 100px;
  margin: 15px 3px;
  border-radius: 50%;
}
p.dashtl_p6 {
  margin: 0 10px;
  font-size: 29px;
  text-transform: capitalize;
}
.dashtl1_card_col2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
p.dashtl_card_p1 {
  font-size: 12px;
  margin: 0 5px;
  font-weight: 500;
  color: #9f9f9f;

}
p.dashtl_card_p2 {
  margin: 0;
  font-weight: 500;
  color: #5524fb;
}

img.dot3_svg {
  height: 25px;
  width: 6px;
  margin-left: 30px;
}
.dashtl_card_row2 {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  line-height: 30px;

}
.dashtl_smallcard {
  width: 23%;
  background: #fafafa;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}
p.dashtl_p4 {
  color: #5524fb;
  font-weight: 600;
  font-size: 26px;
  margin: 0;
}
p.dashtl_p5 {
  margin: 0;
  color: #121212;
  font-weight: 500;
  font-size: 14px;
}
.dashtl_card_col3 {
  cursor: pointer;
  height: 50px;
}
.dashtl_dropdown {
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 30px #9d9d9d41;
  border-radius: 5px;
  margin-left: -128px;
  margin-top: -8px;
  width: 162px;
}
.dashtl_drop1 {
  font-size: 12px;
  margin: 5px;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
}
p.drop_p1 {
  margin: 0;
  margin-right: 10px;
}
.dashtl_header {
  background: #121212;
  display: flex;
  padding: 25px;
  flex-direction: column;
}

p.dashtl_h_p1 {
  margin: 0;
  margin-bottom: -5px;
  color: #8f6efc;
  font-size: 16px;
}

p.dashtl_h_p5 {
  color: #afafaf;
  margin: 0;
  text-align: center;
  font-size: 12px;
}
p.dashtl_h_p2 {
  color: #1da537;
  font-size: 15px;
  margin: 0px;
  margin-bottom: -5px;
}
.dashtl_h_col3 {
  display: flex;
  justify-content: space-between;
  width: 55%;
  height: 120px;
}
.dashtl_h_cards {
  border-radius: 10px;
  background: #946cff;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
p.dashtl_h_p3 {
  color: #fff;
  font-size: 18px;
  margin: 0;
  font-weight: 300;
}
p.dashtl_h_p4 {
  color: #fff;
  font-size: 35px;
  margin: 5px;
  font-weight: 600;
}
.dashtl_drop1:hover {
  background-color: #ece7fd;
  color: #121212;
  border-radius: 5px;
}
img.dash_active {
  margin: 5px;
}

.tl_list_row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.training_assign {
  color: #fff;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
}
img.complete_track {
  width: 15px;
  height: 15px;
}
.track_tl {
  display: flex;
  justify-content: space-between;
}

.dash_tl_line {
  border-bottom: 2px dashed #8f6efc;
  width: 78px;
  position: absolute;
  margin-left: 72px;
  margin-top: 6px;
}

.dash_tl_line1 {
  border-bottom: 2px dashed #777777;
  width: 78px;
  position: absolute;
  margin-left: 182px;
  margin-top: 6px;
}
.dashtl_h_col2 {
  text-align: center;
  margin-left: 50px;
}

img.back_butt {
  margin: 0px;
  margin-top: -30px;
  cursor: pointer;
}
img.profile_tl {
  width: 60px;
  height: 60px;
  margin-top: 10px;
  border-radius: 50%;
}
h3.tl_list_h3 {
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
  text-align: center;
  margin: 0;
}

.dashtl_h_row2 {
  background: #333333;
  border-radius: 5px;
  padding: 10px 18px;
  margin-top: 10px;
}
img.icon_svg {
  width: 45px;
  height: 45px;
  cursor: pointer;
}
.dashtl_h_col4 {
  width: 13%;
  display: flex;
  justify-content: space-around;
}
.tl_list_row2 {
  margin: 0px 30px;
}

.tl_dropdown {
  position: absolute;
  width: 165px;
  right: 15px;
  box-shadow: 10px 10px 30px #9d9d9d41;
  border-radius: 5px;
  z-index: 5;
  background: #fff;
  margin-top: -11px;
  padding: 2px 10px;
}
.dashtl_header1 {
  background: #121212;
  display: flex;
  padding: 25px;
  flex-direction: column;
  width: 90%;
  margin: auto;
  margin-top: 50px;
}

.container2 {
  padding: 25px;
  background: #FAFAFA;
  width: 90%;
  margin: auto;
  display: flex;
}

.bar_container {
  width: 100%;
  object-fit: contain;
  contain: content;
  overflow: overlay;
  box-shadow: 0px 15px 30px #B9B9B929;
border-radius: 20px;
}
.calender_container {
  display: flex;
  width: 40%;
  padding: 20px;
  justify-content: center;
  box-shadow: 0px 15px 30px #b9b9b929;
  border-radius: 15px;
  margin-left: 30px;
  height: 400px; 
  overflow: overlay;
  border: 1px solid lightgray;
}
.bar_container {
  padding: 10px 40px !important;
  margin-left: 1px;
}
.model_contain {
  border: none;
  overflow: overlay;
}
.tablediv {
  overflow: overlay;
  contain: content;
}
.empty-cell {
  width: 15px !important;
  height: 15px !important;
}
.days {
  height: 8!important;
  width: 15px !important;
}
tr {
  width: 40% !important;
}
.dashtl_card_row3 {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  margin: 0;
  margin-left: 15px;
}
p.tllist_active {
  margin: 0;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 5px;
  color: #1DA537;
  display: flex;
  justify-content: center;
  background: #afeab2;
  text-transform: capitalize;
} */

.fseStatsCard{
  background-color: #394CC6;
}
.fseStatsToday{
  background-color: #7E8EF0;
  color: black;
    margin-top: -8px;
    height: 23px;
    width: 75px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 5px;
}
.checkedin{
  font-size: 10px;
    margin-top: 3px;
    margin-right: 10px;
    min-width: 45px;
}