.myTeam_card {
    box-shadow: 0px 0px 20px #d9d9d967;
    border-radius: 20px;
    padding: 20px;
    margin: 20px 0;
    background: #fff;
    width: 100%;
}
.myteam_container {
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    margin: 30px 0;
    padding: 1px 10px;
}
.myteam_col0 {
    min-width: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

img.prof_img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}
.myteam_col2 {
    min-width: 130px;
    margin: 0 10px;
    margin-top: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.myteam_card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 5px 7px 40px #dedede1a;
    border-radius: 15px;
    margin: 15px;
    padding: 20px;
    display: flex;
  }

  .myteam_p2{
    margin: 1px;
    font-size: 23px;
    font-weight: 500;
    color: #9D81FC;
}
p.myteam_p3 {
    margin: 0;
    margin-top: 5px;
}
p.myteam_p1 {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
}
img.svg_myteam {
    width: 30px;
    height: 30px;
    margin-top: -7px;
}
.myteam_col3 {
    margin: initial;
    position: absolute;
    right: 4%;
    height: 92px;
    cursor: pointer;
}
.myteam_dropdown {
    position: absolute;
    width: 165px;
    right: 70px;
    box-shadow: 10px 10px 30px #9d9d9d41;
    border-radius: 5px;
    z-index: 5;
    background: #fff;
    margin-top: 13px;
    padding: 2px 10px;
}
p.myteam_drop_p1 {
    padding: 3px;
    padding-left: 18px;
    font-size: 8px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 0;

}

.myteam_drop_p1:hover {
    background-color: #B198FF;
    color: #fff;
    padding: 5px;
    padding-left: 15px;


}
.myteam_popup {
    background: #f9f9f9 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    padding: 20px 40px;
    margin: 110px;
    margin-top: 100px;
}
h1.myteam_add_tl {
    color: #db23fb;
    font-weight: 400;
    font-size: 17px;
    margin: 0;
}
.myteam_p4{
    margin: 1px;
    font-size: 23px;
    font-weight: 500;
    color: #db23fb;
}
.myteam_button {
    padding: 10px 28px;
    border: none;
    border-radius: 5px;
    background: #9d81ff;
    color: #fff;
    font-size: 17px;
    margin: 10px;
    cursor: pointer;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: center;
}
.myteam_col4 {
    display: flex;
    align-items: center;
}
.my_team_flex {
    display: flex;
    justify-content: flex-end;
}
.my_stats_row3 {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.dashtl_dropdown1 {
    position: absolute;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 10px 10px 30px #9d9d9d41;
    border-radius: 5px;
    margin-left: 302px;
    margin-top: 10px;
    width: 162px;
    padding: 8px;

}

img.profile_tl2 {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 71px;
}