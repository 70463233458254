.App {
  text-align: center;
}

*::-webkit-scrollbar {
  display: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: #121212 !important;
}
/* div{
  text-align: center;
} */
.reroutetoDash {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.GtdButton {
  font-weight: 600;
  margin-left: 38px;
  padding: 5px 20px;
  background: lightgreen;
  border-radius: 10px;
}
.GtdButton:hover {
  box-shadow: 0px 0px 18px 9px lightgreen;
}
.sr-only {
  display: none;
}
.spinner-grow {
  display: inline-block;
  width: 10px !important;
  height: 10px !important;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}
.text-primary {
  color: #ad85ee !important;
}

.ixKURx:not(:last-of-type) {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #fafafa;
  margin-bottom: 10px;
}

.ixKURx {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  color: black;
  background-color: #f2f2f2;
  min-height: 48px;
  background: white !important;
  border-radius: 12px !important;
  padding: 10px 6px !important;
}
.hNSocv {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  color: black;
  min-height: 48px;
  background: white !important;
  padding: 5px 10px !important;
  border-radius: 15px !important;
  margin-bottom: 10px !important;
}