/*
#ebe6fe -bg small card
#DB23FB - pink
#802DF1 - text purple
#9D81FF - button
*/

.dash_card {
  background: #fff;
  width: 43%;
  margin: 20px;
  padding: 15px;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
}

.dashboard {
  display: flex;
  width: 100%;
}
.dashboard_col1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  padding: 20px;
  min-height: 717px;
}
p.dash_pro_p1 {
  color: #8258ed;
  font-size: 13px;
  margin: 5px;
}
.dash_pro_row1 {
  display: flex;
  background: #d8d5ff 0% 0% no-repeat padding-box;
  margin: 0 -19px;
  border-right: 5px solid #9d81ff;
  margin-top: 20px;
  padding: 10px 25px;
}
img.dash_document {
  width: 25px;
  height: 25px;
  margin-top: 2px;
}
.dash_row1 {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #88888843;
  height: 80px;
}

img.dash_profile {
  width: 45px;
  height: 45px;
  margin-top: 16px;
  margin: 0 3px;
  border-radius: 50%;
}
h3.dash_list_head {
  font: normal normal normal 16px/18px poppins;
  color: #832595;
  margin: 18px 26px 0px;
  text-transform: capitalize;
}

.dash_row3,
.dash_row2,
.sub_row,
.d_row2,
.d_row1 {
  display: flex;
}

.dash_row3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
img.company_pro {
  min-width: 55px;
  height: 55px;
  margin: 0px;
}
div.dash_p1 {
  font-size: 11px;
  margin: 17px 6px;
  color: #777777;
}
div.dash_h1 {
  font-size: 16px;
  margin: 13px;
  margin-left: 3px;
  font-weight: 400;
}

.dash_col1 {
  width: 65%;
  background: #ebe6fe;
  border-radius: 5px;
  margin-left: -15px;
}
.dash_col2 {
  width: 40%;
}

p.pro_name {
  color: #5c5c5c;
  font-size: 9px;
  display: flex;
  align-items: center;
  margin: 5px;
  margin-left: 15px;
}
p.pro_name1 {
  color: #802df1;
  font-size: 11px;
  display: flex;
  align-items: center;
}

.d_row2 {
  margin-top: 10px;
}

h2.d_header {
  color: #db23fb;
  font-size: 15px;
  font-weight: 400;
  margin: 5px;
  margin-top: 14px;
}

p.d_p1 {
  font-size: 10px;
  margin: 7px;
}

.d_row4 {
  display: flex;
  justify-content: flex-end;
}

.dash_button {
  padding: 10px 28px;
  border: none;
  border-radius: 5px;
  background: #9d81ff;
  color: #fff;
  font-size: 17px;
  margin: 10px;
  cursor: pointer;
  display: flex;
}

p.dash_b_p1 {
  margin: 0;
  font-size: 14px;
  margin-right: 10px;
}
img.view_but {
  height: 21px;
  width: 20px;
  margin-top: 1px;
}
a {
  text-decoration: none;
}
.dashboard_total {
  padding: 10px 50px;
}
.dashboard_col2 {
  width: 100%;
}
.d_row3 {
  display: flex;
  justify-content: center;
  margin: 15px 0px;
  align-items: center;
  flex-wrap: wrap;
}
.dash_small_card {
  width: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e8fafc;
  border: 1px solid #aadbe0;
  border-radius: 10px;
  margin: 5px 5px;
}
p.dash_small_p1 {
  margin: 0;
  margin-top: 11px;
  font-size: 12px;
}
p.dash_small_p2 {
  margin: 10px;
  font-size: 20px;
  color: #44cdde;
}
p.dash_small_p3 {
  margin: 0;
  margin-bottom: 15px;
  font-size: 13px;
  background: #28b1cb33;
  padding: 1px 5px;
}

.logout_box {
  cursor: pointer;
  background: #eaeaea;
  border-radius: 5px;
  width: 115px;
  height: 31px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 12px;
}
.dash_header_col1 {
  margin-left: 30px;
}
.p1-container {
  margin: 10px 30px;
}
.p1-select-tag-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.stats-card-container {
  display: flex;
  justify-content: space-evenly;
}
.my-card {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin-top: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  height: 150px;
}
.light-green {
  background-color: #ddf0ec;
  border-radius: 10px;
  border: 1px solid #72c1b1;
  color: #72c1b1;
}

.light-orange {
  background-color: #fcf0df;
  border-radius: 10px;
  border: 1px solid #ffa31d;
  color: #ffa31d;
}
.light-brown {
  background-color: #f0f4ff;
  border-radius: 10px;
  border: 1px solid #00818d;
  color: #00818d;
}
.cream {
  background-color: #f0f4ff;
  border-radius: 10px;
  border: 1px solid #5b69ce;
  color: #5b69ce;
}
.p1-select-tag {
  padding: 5px 10px;
  border-radius: 5px;
  color: #8f6efc;
  background: #e4ddff;
  border: 1px solid #805aff;
}
.p1-table-container {
  margin-top: 30px;
}
.makeFixed {
  position: fixed;
  width: 100%;
  height: 54px;
  z-index: 1;
  background-color: white;
}
.addCm-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 52px;
}
.monthly {
  font-weight: 500;
  font-size: 25px;
}

.my_card_p1 {
  font-size: 19px;
  color: #121212;
}
.my_card {
  padding: 2px 10px;
  border-radius: 5px;
  color: #121212;
  font-size: 12px;
  font-weight: 500;
}
.padding_card_p1 {
  background: #b6e8dc;
}
.padding_card_p2 {
  background: #fdd39a;
}
.padding_card_p3 {
  background: #c5edf0;
}
.padding_card_p4 {
  background: #c3caff;
}
.button_add {
  border: none;
  background: #855bf5;
  color: #fff;
  border-radius: 5px;
  margin: 0 8px;
  font-size: 12px;
  display: flex;
  justify-content: space-around;
  width: 155px;
  height: 42px;
  align-items: center;
  cursor: pointer;
}
img.add_cm_image {
  width: 20px;
}

.city_manager_name {
  font-size: 23px;
  margin-left: 28px;
  color: #db23fb;
}
.header-material {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 54px;
  align-items: center;
}
p.dash_header_p1 {
  margin: 0;
  font-size: 13px;
}
h1.dash_head_name {
  font: normal normal normal 22px/24px poppins;
  font-weight: 400;
  margin: 0;
  margin-left: 8px;
}
.dash_header_col2 {
  display: flex;
  align-items: flex-end;
  margin-left: 30px;
  margin-top: 20px;
}

.main-body {
  width: 87vw;
  margin-top: 37px;
  min-height: 100vh;
}
.search-container {
  padding: 5px 20px;
  border: 1px solid lightgray;
  border-radius: 20px;
  background-color: white;
}
.search-input {
  outline: none;
  border: none;
  margin-right: 10px;
}
.filter-option {
  border: none;
  outline: none;
}
.nav-tab {
  width: 130px !important;
}
